<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ categoryData.id ? 'Editar' : 'Nueva' }} Categoría
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Label -->
          <!-- <validation-provider
            #default="validationContext"
            name="Label"
            rules="required"
          >
            <b-form-group
              label="Label"
              label-for="label"
            >
              <b-form-input
                id="label"
                v-model="categoryData.label"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                class="text-uppercase"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Parent -->
          <validation-provider
            #default="validationContext"
            name="Categoría Padre"
          >
            <b-form-group
              label="Categoría Padre"
              label-for="parent"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="categoryData.parent"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                :clearable="false"
                input-id="parent"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Categoría"
            rules="required"
          >
            <b-form-group
              label="Categoría"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="categoryData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <!-- <validation-provider
            #default="validationContext"
            name="Pathname"
          >
            <b-form-group
              label="Pathname"
              label-for="pathname"
            >
              <b-form-input
                id="pathname"
                v-model="categoryData.pathname"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Status -->
          <validation-provider
            name="Activa"
          >
            <b-form-group
              label="Activa"
              label-for="statusCheck"
            >
              <b-form-checkbox
                id="statusCheck"
                v-model="categoryData.status"
                name="statusCheck"
              >
                {{ categoryData.status ? 'Si' : 'No' }}
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <template v-if="loading">
                <b-spinner
                  variant="light"
                  class="mr-75"
                  small
                />
                <span>{{ categoryData.id ? 'Actualizando' : 'Creando' }}</span>
              </template>
              <span v-else>{{ categoryData.id ? 'Actualizar' : 'Crear' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="loading"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countriesList from '@/@fake-db/data/other/countries'
// import store from '@/store'
import { types } from '@core/constants'
import useCategories from './useCategories'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countriesList,
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if (val && this.item) {
        this.categoryData = {
          id: this.item.id,
          name: this.item.name,
          status: this.item.status,
        }
      } else {
        this.categoryData = {
          id: null,
          name: '',
          status: true,
        }
        this.$emit('update:item', null)
      }
    },
  },
  setup(_, { emit }) {
    const blankData = {
      id: null,
      name: '',
      status: true,
    }

    const typeList = ref(types)

    const categoryData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankData))
    }

    const loading = ref(false)
    const {
      categories,
      store,
      update,
      fetchSelector,
    } = useCategories()

    const onSubmit = async () => {
      loading.value = true

      const data = { ...categoryData.value }

      data.parent = data.parent?.value

      try {
        if (data.id) {
          await update(data.id, data)
        } else {
          await store(data)
        }

        fetchSelector(false).then(list => {
          categories.value = list
        })
        emit('refetchData')
        emit('update:is-add-new-user-sidebar-active', false)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      categories,
      categoryData,
      loading,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      typeList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
